import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { darken } from 'polished';
import styled from 'styled-components';
import { UserContext } from '@helpers';
import MESSAGES from '@messages';
import theme from '../styled/theme';
import InfoPanel from './InfoPanel';
import dayjs from 'dayjs';

const Wrapper = styled.div`
    border: ${theme.colors.bg} solid 1px;
    box-sizing: border-box;
    border-radius: 0.5em;
    padding: ${theme.calendar.eventPadding};
    text-align: left;
    font-size: 0.6em;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    min-height: 100%;
    background: ${theme.colors.yellowLight};
    max-width: ${(props) => props.maxWidth ? props.maxWidth : '100%'};
    left: ${(props) => (props.left || 0)};
    cursor: pointer;
    background: ${(props) => props.background};

    &:hover, &:focus {
      max-width: 100%;
      left: 0;
      z-index: 10;
    }
`;

const FullWidth = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
`;

const Time = styled.div``;
const Name = styled.div`
  font-weight: bold;
  line-height: 1.2;
`;

const Vacancy = styled.div`
  position: absolute;
  bottom: 0.5em;
  right: 0.5em;
  left: auto;
  top: auto;
  text-align: right;
`;

const HasVacancy = styled.strong`
  color: ${(props) => props.color ? darken(0.3, props.color) : 'inherit'};
`;


const Event = ({
  event, events, isNearRight, ...props
}) => {
  const [isOpen, setOpen] = useState(false);

  const { isLogged } = useContext(UserContext);

  const {
    start_time,
    end_time,
    summary,
    name,
    overlaps,
    vacancies,
    id,
    agenda_id,
    group_id,
    color_id = 0,
    events_length,
    date,
  } = event;

  const maxWidth = overlaps && overlaps.length ? `${100 / (overlaps.length)}%` : '100%';
  const wholeDay = start_time === '00:00' && end_time === '00:00';
  const multiplier = overlaps.findIndex((overlap) => overlap.id === id);
  const left = `calc(${multiplier} * ${maxWidth})`;
  const background = theme.calendar.colors[color_id];

  let time = events_length === 1 ? `${dayjs(date).format('DD.MM.YYYY')} ` : '';
  time += wholeDay ? MESSAGES.ALL_DAY : `${start_time} - ${end_time}`;

  return (
    <FullWidth>
      <Wrapper
        {...props}
        data-id={id}
        data-group-id={group_id}
        data-agenda-id={agenda_id}
        maxWidth={maxWidth}
        left={left}
        background={background}
        onClick={() => setOpen(!isOpen)}
      >
        <Time>{time}</Time>
        <Name>{summary || name}</Name>
        {/* {isLogged && (
          <Vacancy>
            {vacancies > 0
              ? <HasVacancy color={background}>{MESSAGES.VACANCIES}</HasVacancy>
              : MESSAGES.NO_VACANCIES}
          </Vacancy>
        )} */}
      </Wrapper>
      {isOpen && (
        <InfoPanel
          isNearRight={isNearRight}
          setOpen={setOpen}
          events={events}
        />
      )}
    </FullWidth>
  );
};

Event.defaultProps = {
  event: {},
};

const eventPropTypes = PropTypes.shape({
  name: PropTypes.string,
  start_time: PropTypes.string,
  end_time: PropTypes.string,
  summary: PropTypes.string,
  description: PropTypes.string,
  excerpt: PropTypes.string,
  vacancies: PropTypes.number,
  id: PropTypes.number,
  agenda_id: PropTypes.number,
  group_id: PropTypes.number,
  color_id: PropTypes.number,
  overlaps: PropTypes.array,
});

Event.propTypes = {
  isNearRight: PropTypes.bool,
  event: eventPropTypes,
  events: PropTypes.arrayOf(eventPropTypes),
};

export default Event;
