import React, { useReducer, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { auth, UserContext } from '@helpers';
import * as Sentry from '@sentry/gatsby';
import { NEW_API_URL } from '../constants';

const INITIAL_STATE = {
  isLogged: false,
  isLoading: true,
  client: { fields: {}, participants: [] },
  upcommingMonthgroups: [],
  networkError: false,
  isSuperUser: false,
};

export const ACTIONS = {
  LOGIN: 'login',
  SET_CLIENT: 'set-client',
  SET_NETWORK_ERROR: 'set-network-error',
  SET: 'set',
  ADD_PARTICIPANT: 'add-participant',
  LOADING: 'loading',
  SET_INITIAL: 'set-initial',
  CLEAR_UPCOMMING: 'clear-upcomming',
};

function reducer(state, { type, data, participant }) {
  switch (type) {
    case ACTIONS.SET_INITIAL:
      return INITIAL_STATE;

    case ACTIONS.SET_NETWORK_ERROR:
      return { ...INITIAL_STATE, networkError: true };

    case ACTIONS.LOADING:
      return { ...state, isLoading: true };

    case ACTIONS.SET:
      Sentry.setUser({ email: data.client.email });
      return {
        ...state, ...data, isLoading: false, isLogged: true,
      };

    case ACTIONS.ADD_PARTICIPANT:
      return {
        ...state,
        client: {
          ...state.client,
          participants: [...state.client.participants, participant],
        },
      };

    case ACTIONS.CLEAR_UPCOMMING:
      return { ...state, upcommingMonthgroups: [] };
    default:
      throw new Error();
  }
}

const UserProvider = ({ children, pageconfig }) => {
  const { login_disabled } = pageconfig;
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const fetchInitialized = useRef(false);

  const logout = () => {
    dispatch({ type: ACTIONS.SET_INITIAL });
    auth.clearToken();
  };

  const login = ({ jwt, ...data }, remember = false) => {
    if (jwt) {
      auth.setToken(jwt, remember);
      auth.setAxiosHeaders(jwt);
      dispatch({ type: ACTIONS.SET, data });
    } else {
      dispatch({ type: ACTIONS.SET_INITIAL });
    }
  };

  const fetchClient = useCallback(() => {
    dispatch({ type: ACTIONS.LOADING });

    return axios.get(`${NEW_API_URL}/client`)
      .then(({ data: { ok, upcomming_monthgroups = [], ...data } }) => {
        if (ok) {
          const upcommingMonthgroups = upcomming_monthgroups
            .flat(Infinity);
          dispatch({ type: ACTIONS.SET, data: { ...data, upcommingMonthgroups } });
        } else {
          dispatch({ type: ACTIONS.SET_INITIAL });
        }
      })
      .catch((err = {}) => {
        const { response: { status } = {} } = err;
        if (status === 401) {
          logout();
        } else {
          const { message = '' } = err;
          if (message === 'Network Error') dispatch({ type: ACTIONS.SET_NETWORK_ERROR });
          else logout();
        }
      });
  }, []);

  useEffect(() => {
    if (!state.isLogged) return;
    const hasCashClients = state.isSuperUser && !state.cashClients;
    if (!hasCashClients) return;

    axios
      .get('/cash-clients')
      .then(({ data: cashClients }) => {
        dispatch({ type: ACTIONS.SET, data: { cashClients } });
      })
      .catch((err) => {
        dispatch({ type: ACTIONS.SET_INITIAL });
        return err;
      });
  }, [state.isLogged, state.isSuperUser, state.cashClients]);

  useEffect(() => {
    if (login_disabled) return;
    if (fetchInitialized.current) return;
    fetchInitialized.current = true;

    const token = auth.getToken();
    if (token) {
      auth.setAxiosHeaders(token);
      fetchClient();
    } else {
      auth.setAxiosHeaders(null);
      dispatch({ type: ACTIONS.SET_INITIAL });
    }
  }, [fetchClient, login_disabled]);

  const setClient = useCallback((client) => dispatch({ type: ACTIONS.SET, data: { client } }), [dispatch]);
  const addParticipant = useCallback((participant) => dispatch({ type: ACTIONS.ADD_PARTICIPANT, participant }), [dispatch]);
  const clearUpcomming = useCallback(() => dispatch({ type: ACTIONS.CLEAR_UPCOMMING }), [dispatch]);

  return (
    <UserContext.Provider
      value={{
        ...state,
        pageconfig,
        login,
        logout,
        fetchClient,
        setClient,
        addParticipant,
        clearUpcomming,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.defaultProps = {
  pageconfig: {},
};

UserProvider.propTypes = {
  pageconfig: PropTypes.shape({
    login_disabled: PropTypes.bool,
  }),
  children: PropTypes.elementType,
};

export default UserProvider;
